import React, { useRef } from 'react';
import * as styles from './index.module.scss';
import Squiggly from '../../components/squiggly';
import ScrollablePage from '../../layouts/scrollable-page';
import Footer from '../../components/footer';
import QuickFind from '../../components/privacy-policy/quick-find/quick-find';
import SEO from '../../components/seo';

const PrivacyPolicy = () => {
  const barRef = useRef();
  return (
    <ScrollablePage simpleBarRef={barRef}>
      <SEO 
      title={'Lucky Beard | Global Design and Advisory | Our Privacy Policy'}
       />
      <div className={`${styles.privacyWrapper} scroll-container2`}>
        <div className={styles.header}>
          <h1 className={`title-L ${styles.title}`}>Privacy Notice</h1>
          <p className={`body-M bold ${styles.description}`}>
            This Privacy Notice is effective as of August/1, 2021. Please note
            that this Privacy Notice will be updated regularly to reflect any
            changes in the way we handle your personal data or any changes in
            applicable laws.
          </p>

          <div className={styles.squigglyWrapper}>
            <Squiggly />
          </div>
        </div>

        <div className={`${styles.container}`}>
          <QuickFind scrollBoxRef={barRef} />

          <p className={`body-M ${styles.bm}`}>
            We understand that your privacy is important to you and that you
            care about how your personal data is used. We respect and value the
            privacy of everyone who interacts with us and will only collect /
            use personal data in ways that are described here, and in a way that
            is consistent with our obligations and your rights under the General
            Data Protection Regulation (EU Regulation 2016/679) (the “GDPR”) and
            under any other applicable data protection legislation, including
            the South African Protection of Personal Information Act, 2013.
          </p>

          <h5 id='whoAreWe' className={`title-S ${styles.ts}`}>
            Who Are We?
          </h5>
          <p className={`body-M ${styles.bm}`}>
            Lucky Beard Limited is registered in Ireland under company number
            610580 and offices at 15 Harcourt Street, St. Kevin’s, Dublin 2, D02
            XY47 and Lucky Beard UK Limited is registered under company number
            11409849 and whose registered office is at 1 WestFerry Circus Canary
            Wharf, London E14 4HD.
          </p>

          <h5 id='whatDoesThisCover' className={`title-S ${styles.ts}`}>
            What Does This Privacy Notice Cover?
          </h5>
          <p className={`body-M ${styles.bm}`}>
            This Privacy Notice (“Notice”) describes the manner in which Lucky
            Beard collects, uses, maintains and discloses information from
            visitors to our website, customers, prospective customers, employees
            and prospective employees, in situations in which Lucky Beard is a
            data controller as defined in the GDPR. It also explains your rights
            under the law relating to your personal data.
          </p>

          <p className={`body-M ${styles.bm}`}>
            Where Lucky Beard is required to comply with additional obligations
            imposed by other jurisdictions when it processes information which
            you have provided to it these obligations are set out at the end of
            this Privacy Notice.
          </p>

          <p className={`body-M ${styles.bm}`}>
            For purposes of this Privacy Notice, the terms “user,” “customer,”
            “employees,” “you,” and “your” are meant to refer to the individuals
            about whom we may collect personal information, and at times may be
            used interchangeably within this Notice. The term “Personal data” is
            defined by the GDPR as ‘any information relating to an identifiable
            person who can be directly or indirectly identified’.
          </p>

          <p className={`body-M ${styles.bm}`}>
            If you have any questions or concerns about our use of your personal
            information, please contact us using the contact details provided at
            the end of this Privacy Notice.
          </p>

          <h5
            id='whatPersonalDataDoWeCollect'
            className={`title-S ${styles.ts}`}
          >
            What Personal Data Do We Collect?
          </h5>

          <p className={`body-M ${styles.bm}`}>
            We collect personal data of our employees, potential employees,
            clients, potential clients, suppliers, business contacts and website
            users. If the data we collect are not listed in this privacy notice,
            we will give individuals (when required by law) appropriate notice
            of which other data will be collected and how it will be used.
          </p>

          <p className={`body-M ${styles.bm}`}>
            Below describes the categories of personal data we collect:
          </p>

          <div className={styles.table}>
            <div className={styles.row}>
              <div className={`${styles.column} ${styles.flex} ${styles.f1}`}>
                <p className={'body-S'}> Categories of personal data</p>
              </div>
              <div className={`${styles.column} ${styles.flex} ${styles.f2}`}>
                <div className={styles.hDivider} />
                <div className={`${styles.purpose} body-S`}> Purpose</div>
              </div>
            </div>

            <div className={`${styles.row} ${styles.dividerTop}`}>
              <div className={`${styles.column} ${styles.f1}`}>
                <p className={'body-S'}>
                  Personal details, contact details and identifiers
                </p>
              </div>
              <div className={`${styles.column}  ${styles.f2}`}>
                <p className={`body-S bold ${styles.par}`}>
                  Personal data is collected on our website through forms you
                  complete including registering to events, downloads and
                  newsletter.
                </p>

                <p className={`body-S ${styles.par}`}>
                  Our website also collects personal data about your website
                  visit including information about your computer through 3rd
                  party cookies (see below).
                </p>

                <p className={`body-S ${styles.par}`}>
                  Lucky Beard may also collect personal details for
                  recruitment/employment purposes, such as national
                  identification number, social security number, insurance
                  information, marital/civil partnership status, domestic
                  partners, dependents and emergency contact information.
                </p>

                <p className={`body-S ${styles.par}`}>
                  We may also collect this information when working on projects
                  for our clients.
                </p>
              </div>
            </div>

            <div className={`${styles.row}`}>
              <div className={`${styles.column} ${styles.f1}`}>
                <p className={'body-S'}>
                  Education history, professional information, sensitive data
                  and immigration documents for recruitment
                </p>
              </div>
              <div className={`${styles.column}  ${styles.f2}`}>
                <p className={`body-S ${styles.par}`}>
                  Lucky Beard may collect information about your portfolio,
                  education and professional employment history. Information
                  that you submit in CVs, letters, writing samples, or other
                  written materials (including photographs). Information
                  generated by interviewers and recruiters related to you
                  including any assessments. We may also collect certain type of
                  sensitive information such as background checks, medical
                  information and legal documents such as data on citizenship,
                  passport data, residency, work permits where permitted or
                  required by law or with your consent.
                </p>
              </div>
            </div>

            <div className={`${styles.row} ${styles.dividerTop}`}>
              <div className={`${styles.column} ${styles.f1}`}>
                <p className={'body-S'}>
                  Financial information for payroll, benefits or customer
                  invoicing
                </p>
              </div>
              <div className={`${styles.column}  ${styles.f2}`}>
                <p className={`body-S ${styles.par}`}>
                  We may collect your banking details and other relevant
                  financial details for payroll purposes or in order to conduct
                  business with you.
                </p>
              </div>
            </div>
          </div>

          <h5 id='thirdPartyCookies' className={`title-XS ${styles.ts}`}>
            Third party Cookies
          </h5>

          <p className={`body-M ${styles.bm}`}>
            We collect personal data of our employees, potential employees,
            clients, potential clients, suppliers, business contacts and website
            users. If the data we collect are not listed in this privacy notice,
            we will give individuals (when required by law) appropriate notice
            of which other data will be collected and how it will be used.
          </p>

          <p className={`body-M ${styles.bm}`}>
            When you use our website for the first time, a message will appear
            asking for your consent to the use of Cookies, with a link to
            further details about the types of Cookies used, as well as a link
            to our full Cookies Policy. You may choose to consent to the use of
            all Cookies, or you may specify which types of Cookies may be
            deployed.
          </p>

          <h5 id='whatIsOurLegalBasis' className={`title-S ${styles.ts}`}>
            What is our Legal Basis for Processing?
          </h5>

          <p className={`body-M ${styles.bm}`}>
            Under the GDPR, we must always have a lawful basis for using your
            personal data. The following describes how we will use your personal
            data and our lawful bases for doing so:
          </p>

          <div className={styles.table}>
            <div className={`${styles.row}`}>
              <div className={`${styles.column} ${styles.f1}`}>
                <p className={'body-S'}>
                  For the purpose of marketing communication and interactions on
                  our website, including when you request information from us,
                  sign up to newsletters, complete web forms or surveys
                </p>
              </div>
              <div className={`${styles.column}  ${styles.f1}`}>
                <p className={`body-S ${styles.par}`}>
                  Based on consent given by the data subject
                </p>
              </div>
            </div>

            <div className={`${styles.row}`}>
              <div className={`${styles.column} ${styles.f1}`}>
                <p className={'body-S'}>
                  For the purpose of promoting our products and services to you
                  in general
                </p>
              </div>
              <div className={`${styles.column}  ${styles.f1}`}>
                <p className={`body-S ${styles.par}`}>
                  Based on consent given by the data subject or our legitimate
                  interests to communicate with our customers
                </p>
              </div>
            </div>

            <div className={`${styles.row}`}>
              <div className={`${styles.column} ${styles.f1}`}>
                <p className={'body-S'}>
                  For the purpose of managing our contractual obligations we
                  have with you
                </p>
              </div>
              <div className={`${styles.column}  ${styles.f1}`}>
                <p className={`body-S ${styles.par}`}>
                  Necessary for the performance of a contract
                </p>
              </div>
            </div>

            <div className={`${styles.row}`}>
              <div className={`${styles.column} ${styles.f1}`}>
                <p className={'body-S'}>
                  For the purpose of operating and managing our business
                  operations
                </p>
              </div>
              <div className={`${styles.column}  ${styles.f1}`}>
                <p className={`body-S ${styles.par}`}>
                  On the basis of our legitimate interests for ensuring the
                  proper functioning of our business operations
                </p>
              </div>
            </div>

            <div className={`${styles.row}`}>
              <div className={`${styles.column} ${styles.f1}`}>
                <p className={'body-S'}>
                  Managing our contractual obligations as an employer including
                  performing any administrative functions (e.g. expenses,
                  benefits)
                </p>
              </div>
              <div className={`${styles.column}  ${styles.f1}`}>
                <p className={`body-S ${styles.par}`}>
                  Necessary for the performance of a contract
                </p>
              </div>
            </div>

            <div className={`${styles.row}`}>
              <div className={`${styles.column} ${styles.f1}`}>
                <p className={'body-S'}>
                  Performing any legally required reporting and to respond to
                  legal process related to employment or business operations
                </p>
              </div>
              <div className={`${styles.column}  ${styles.f1}`}>
                <p className={`body-S ${styles.par}`}>
                  Necessary for the compliance with a legal obligation to which
                  we are subject
                </p>
              </div>
            </div>

            <div className={`${styles.row}`}>
              <div className={`${styles.column} ${styles.f1}`}>
                <p className={'body-S'}>
                  Manage applications from prospective employees
                </p>
              </div>
              <div className={`${styles.column}  ${styles.f1}`}>
                <p className={`body-S ${styles.par}`}>Based on your consent</p>
              </div>
            </div>

            <div className={`${styles.row}`}>
              <div className={`${styles.column} ${styles.f1}`}>
                <p className={'body-S'}>
                  Monitoring your use of our systems (including monitoring the
                  use of our website and any apps and tools you use)
                </p>
              </div>
              <div className={`${styles.column}  ${styles.f1}`}>
                <p className={`body-S ${styles.par}`}>
                  On the basis of our legitimate interests of avoiding
                  non-compliance and protecting our reputation.
                </p>
              </div>
            </div>
          </div>

          <p className={`body-M ${styles.bm}`}>
            Where the above table states that we rely on our legitimate
            interests for a given purpose, we are of the opinion that our
            legitimate interests are not overridden by your interests, rights or
            freedoms.
          </p>

          <p className={`body-M ${styles.bm}`}>
            Lucky Beard does not knowingly collect personal information from
            children under the age of 16. We do not provide services to
            children, nor do we market to children.
          </p>

          <h5 id='processingPersonalData' className={`title-S ${styles.ts}`}>
            Processing Personal Data for Marketing
          </h5>

          <p className={`body-M ${styles.bm}`}>
            With your permission and/or where permitted by law, we will use your
            personal data for marketing purposes, which may include contacting
            you by email AND/OR telephone with information, news and offers on
            our services. You will not be sent any unlawful marketing or spam.
            We will always work to fully protect your rights and comply with our
            obligations under the Data Protection Legislation, and you will
            always have the opportunity to opt-out.
          </p>

          <p className={`body-M ${styles.bm}`}>
            We will only use your personal data for the purpose(s) for which it
            was originally collected unless we reasonably believe that another
            purpose is compatible with that or those original purpose(s) and
            need to use your personal data for that purpose.
          </p>

          <p className={`body-M ${styles.bm}`}>
            The bulk of the personal data we collect and use for marketing
            purposes relates to individuals employed by our clients and other
            companies we work with. We may also obtain contact information from
            public sources, including content made public on social media sites,
            to make an initial contact with a relevant individual.
          </p>

          <p className={`body-M ${styles.bm}`}>
            Like most companies, Lucky Beard has customer relationship
            management (CRM) database to manage and track our relationship with
            customers. Personal data used for this purpose includes contact
            data, publicly available information such as social media posts,
            your responses to targeted mailing, web activity of registered
            users. If you wish to be excluded from our CRM databases please
            contact us.
          </p>

          <h5 id='doWeShare' className={`title-S ${styles.ts}`}>
            Do We Share Your Personal Data?
          </h5>

          <p className={`body-M ${styles.bm}`}>
            We may sometimes share your data with a third party to supply
            services on our behalf. In some cases, the third parties may require
            access to some or all of your data. Where any of your data is
            required for such a purpose, we will take all reasonable steps to
            ensure that your data will be handled safely, securely, and in
            accordance with your rights.
          </p>

          <p className={`body-M ${styles.bm}`}>
            We may share personal data with third parties that provide services
            to us such as billing/ payment processing, HR, web publishing,
            marketing services, customer support, email processing,
            communication interfaces, web/application hosting and CRM services.
          </p>

          <p className={`body-M ${styles.bm}`}>
            We are careful only to share the information that is necessary for
            the purposes described. Any third party who receives this
            information is bound by a contract with Lucky Beard setting out
            their obligation in relation to your data as required per Article 28
            of the GDPR.
          </p>

          <p className={`body-M ${styles.bm}`}>
            We may also be required to disclose data to third parties who are
            not data processors acting on our behalf of Lucky Beard. Categories
            of recipients include:
          </p>

          <ul>
            <li className={`body-M ${styles.bm}`}>
              Tax authorities (e.g. Irish Revenue Commissioners)
            </li>
            <li className={`body-M ${styles.bm}`}>
              Law enforcement (where required for the investigation, detection,
              or prosecution of criminal offences)
            </li>
          </ul>

          <p className={`body-M ${styles.bm}`}>
            Lucky Beard takes strong measures to help protect your data from
            inappropriate access or use by unauthorized persons. We take all
            necessary steps to ensure that your data will be given adequate
            protection as required under the GDPR and Lucky Beard’s own internal
            policies.
          </p>

          <h5 id='internationalTransfers' className={`title-S ${styles.ts}`}>
            International Transfers
          </h5>

          <p className={`body-M ${styles.bm}`}>
            Lucky Beard will, from time to time, make use of services provided
            by 3rd parties which may make the transfer of personal data outside
            the EU/EEA necessary. For example, we use a variety of cloud-based
            tools such as Bamboo HR, Skype, Office365, and similar.
          </p>

          <p className={`body-M ${styles.bm}`}>
            Unless stated otherwise, transfers of personal data from within the
            European Economic Area (EEA) to third parties outside the EEA are
            based on an adequacy decision or are governed by the standard
            contractual clauses (SCC). Any other non-EEA related transfers of
            your personal data will take place in accordance with the
            appropriate international data transfer mechanisms and standards.
          </p>

          <h5 id='dataKeeping' className={`title-S ${styles.ts}`}>
            How Long Will We Keep Your Personal Data?
          </h5>

          <p className={`body-M ${styles.bm}`}>
            We will retain your personal data only for as long as necessary for
            the purposes outlined above related services provided to you, to
            comply with our legal obligations, resolve disputes, and enforce our
            agreements.
          </p>

          <p className={`body-M ${styles.bm}`}>
            We maintain specific records management and retention policies and
            procedures, so that personal data is deleted according to the
            following retention key criteria:
          </p>

          <ul>
            <li className={`body-M ${styles.bm}`}>
              As long as we have an ongoing and active relationship with you (in
              particular, if you have a contract with us).
            </li>
            <li className={`body-M ${styles.bm}`}>
              As long as we have your consent keeping you informed.
            </li>
            <li className={`body-M ${styles.bm}`}>
              As long as it is needed in order to comply with our global legal
              and contractual obligations.
            </li>
          </ul>

          <h5 id='keepSecure' className={`title-S ${styles.ts}`}>
            How Do We Keep Your Data Secure?
          </h5>

          <p className={`body-M ${styles.bm}`}>
            We are committed to ensuring that your information is secure with us
            and with any third parties who may act on our behalf.
          </p>

          <p className={`body-M ${styles.bm}`}>
            ​All staff working for Lucky Beard have a legal duty to keep
            information about you confidential and all staff are aware of our
            information security policy. We take a number of important measures
            defined in our security policies, including the following:
          </p>

          <ul>
            <li className={`body-M ${styles.bm}`}>
              limiting access to your personal data to those employees, agents,
              contractors, and other third parties with a legitimate need to
              know and ensuring that they are subject to duties of
              confidentiality
            </li>
            <li className={`body-M ${styles.bm}`}>
              procedures for dealing with data breaches (the accidental or
              unlawful destruction, loss, alteration, unauthorised disclosure
              of, or access to, your personal data) including notifying you
              and/or the Data Protection Commission’s Office when we are legally
              required to do so
            </li>
            <li className={`body-M ${styles.bm}`}>
              training for staff in data protection policies and procedures
            </li>
          </ul>

          <h5 id='myRights' className={`title-S ${styles.ts}`}>
            What are my Rights?
          </h5>

          <p className={`body-M ${styles.bm}`}>
            Under the GDPR, you have the following rights, which we will always
            work to uphold:
          </p>

          <ul>
            <li className={`body-M ${styles.bm}`}>
              The right to be informed about our collection and use of your
              personal data. This Privacy Notice should tell you everything you
              need to know, but you can always contact us to find out more or to
              ask any questions.
            </li>
            <li className={`body-M ${styles.bm}`}>
              The right to access the personal data we hold about you.
            </li>
            <li className={`body-M ${styles.bm}`}>
              The right to have your personal data rectified if any of your
              personal data held by us is inaccurate or incomplete.
            </li>

            <li className={`body-M ${styles.bm}`}>
              The right to be forgotten, i.e. the right to ask us to delete or
              otherwise dispose of any of your personal data that we have.
            </li>
            <li className={`body-M ${styles.bm}`}>
              The right to restrict (i.e. prevent) the processing of your
              personal data.
            </li>
            <li className={`body-M ${styles.bm}`}>
              The right to object to us using your personal data for a
              particular purpose or purposes.
            </li>
            <li className={`body-M ${styles.bm}`}>
              The right to data portability. This means that, if you have
              provided personal data to us directly, we are using it with your
              consent or for the performance of a contract, and that data is
              processed using automated means, you can ask us for a copy of that
              personal data to re-use with another service or business in many
              cases.
            </li>
            <li className={`body-M ${styles.bm}`}>
              Rights relating to automated decision-making and profiling. We do
              not use your personal data in this way.
            </li>
          </ul>

          <p className={`body-M ${styles.bm}`}>
            For more information about our use of your personal data or
            exercising your rights as outlined above, please contact us using
            the details provided below.
          </p>

          <p className={`body-M ${styles.bm}`}>
            If you have any cause for complaint about our use of your personal
            data, you have the right to lodge a complaint with the Office of
            Data Protection Commission.
          </p>

          <h5 id='accessPersonalData' className={`title-S ${styles.ts}`}>
            How Can I Access My Personal Data?
          </h5>

          <p className={`body-M ${styles.bm}`}>
            If you want to know what personal data we have about you, you can
            ask us for details of your personal data and for a copy of it (where
            any such personal data is held). This is known as a “subject access
            request”.
          </p>

          <p className={`body-M ${styles.bm}`}>
            All subject access requests should be made in writing and sent to
            the
          </p>

          <p className={`body-M ${styles.bm}`}>
            There is normally no charge for a subject access request. If your
            request is ‘manifestly unfounded or excessive’ (for example, if you
            make repetitive requests) a fee may be charged to cover our
            administrative costs in responding.
          </p>

          <p className={`body-M ${styles.bm}`}>
            We will respond to your subject access request within one month of
            receiving it. Normally, we aim to provide a complete response,
            including a copy of your personal data within that time. In some
            cases, however, particularly if your request is more complex, more
            time may be required up to a maximum of three months from the date
            we receive your request. You will be kept fully informed of our
            progress.
          </p>

          <h5 id='contactYou' className={`title-S ${styles.ts}`}>
            How Do I Contact You?
          </h5>

          <p className={`body-M ${styles.bm}`}>
            To contact us about anything to do with your personal data and data
            protection, including to make a subject access request, please use
            the following details:
          </p>

          <p className={`body-M ${styles.bm}`}>
            <a
              className={`utility-link ${styles.link}`}
              href='mailto:privacy@luckybeard.com'
            >
              privacy@luckybeard.com
            </a>
          </p>

          <h5 id='jurisdictionSA' className={`title-S ${styles.ts}`}>
            Jurisdiction Specific Requirements South Africa
          </h5>

          <p className={`body-M ${styles.bm}`}>
            In addition to the information set out in the Privacy Notice above,
            individuals, including juristic persons, who reside in South Africa
            or have their personal information processed by Lucky Beard in South
            Africa will have certain rights under the Protection of Personal
            Information Act, 2013 (“POPIA”) and any regulations thereto.
          </p>

          <p className={`body-M ${styles.bm}`}>
            For the purposes of POPIA, “personal data” means information
            relating to an identifiable, living, natural person, and where it is
            applicable, an identifiable, existing juristic person.
          </p>
          <p className={`body-M ${styles.bm}`}>
            Where you reside in South Africa, or we process your personal data
            within South Africa the following provisions will be applicable to
            your personal data which we process notwithstanding what is set out
            in the Privacy Notice above.
          </p>

          <p className={`body-M ${styles.bm}`}>
            Lucky Beard Pty Limited is registered in South Africa under company
            number 2014/16429/07 and offices at Southdowns Ridge Office Park,
            1240 John Vorster Avenue, Irene, 0062.
          </p>

          <h5
            id='internationalTransfersLocal'
            className={`title-S ${styles.ts}`}
          >
            International Transfers
          </h5>

          <p className={`body-M ${styles.bm}`}>
            Lucky Beard may, from time to time, make use of services provided by
            3rd parties which may make the transfer of personal data outside of
            South Africa necessary. For example, we use a variety of cloud-based
            tools such as Skype, Office365, and similar.
          </p>

          <p className={`body-M ${styles.bm}`}>
            The transfer of personal information to a country outside of South
            Africa shall take place only if one or more of the following
            applies:
          </p>

          <ul>
            <li className={`body-M ${styles.bm}`}>
              the third party who is the recipient of the information is subject
              to a law, binding corporate rules or binding agreement which
              provide an adequate level of protection that:
            </li>
          </ul>

          <p className={`body-M ${styles.bm}`}>
            (i) effectively upholds principles for reasonable processing of the
            information that are substantially similar to the conditions for the
            lawful processing of personal data relating to a data subject who is
            a natural person and, where applicable, a juristic person; and
          </p>

          <p className={`body-M ${styles.bm}`}>
            (ii) includes provisions, that are substantially similar to the
            requirements set out in POPIA, relating to the further transfer of
            personal data from the recipient to third parties who are in a
            foreign country;
          </p>

          <ul>
            <li className={`body-M ${styles.bm}`}>
              you consent to the transfer;
            </li>
            <li className={`body-M ${styles.bm}`}>
              the transfer is necessary for the performance of a contract
              between you and Lucky Beard, or for the implementation of
              pre-contractual measures taken in response to the data subject’s
              request;
            </li>
            <li className={`body-M ${styles.bm}`}>
              the transfer is necessary for the conclusion or performance of a
              contract concluded in the interest of the data subject between
              Lucky Beard and a third party; or
            </li>
            <li className={`body-M ${styles.bm}`}>
              the transfer is for your benefit, and:
            </li>
          </ul>

          <p className={`body-M ${styles.bm}`}>
            (i) it is not reasonably practicable to obtain your consent to that
            transfer; and
          </p>

          <p className={`body-M ${styles.bm}`}>
            (ii) if it were reasonably practicable to obtain such consent, you
            would be likely to give it.
          </p>

          <p className={`body-M ${styles.bm}`}>
            For the purpose of International Transfers:
          </p>

          <ul>
            <li className={`body-M ${styles.bm}`}>
              ‘‘binding corporate rules’’ means personal information processing
              policies, within a group of undertakings, which are adhered to by
              a responsible party or operator within that group of undertakings
              when transferring personal information to a responsible party or
              operator within that same group of undertakings in a foreign
              country; and
            </li>
            <li className={`body-M ${styles.bm}`}>
              ‘‘group of undertakings’’ means a controlling undertaking and its
              controlled undertakings.
            </li>
          </ul>

          <h5 id='myRightsSA' className={`title-S ${styles.ts}`}>
            What Are My Rights?​​
          </h5>

          <p className={`body-M ${styles.bm}`}>
            Under POPIA, you have the following rights, which we undertake to
            uphold and which include the right to:
          </p>

          <ul>
            <li className={`body-M ${styles.bm}`}>
              request access to and the right to rectify (or correct) the
              information which has been collected about you;
            </li>
            <li className={`body-M ${styles.bm}`}>
              object to the processing of your personal data if you have
              reasonable grounds for believing that such processing:
            </li>
            <li className={`body-M ${styles.bm}`}>
              does not protect your legitimate interests;
            </li>
            <li className={`body-M ${styles.bm}`}>
              is not necessary for the proper performance of a public law duty
              by a public body, if applicable;
            </li>
            <li className={`body-M ${styles.bm}`}>
              is not necessary for pursuing the legitimate interests of Lucky
              Beard or of a third party to whom the information is supplied; or
            </li>
            <li className={`body-M ${styles.bm}`}>
              is being used for purposes of direct marketing other than direct
              marketing by means of unsolicited electronic communications; and
            </li>
          </ul>

          <p className={`body-M ${styles.bm}`}>
            lodge a complaint to the Information Regulator at
          </p>

          <p className={`body-M ${styles.bm}`}>
            <a
              className={`utility-link ${styles.link}`}
              href='mailto:inforeg@justice.gov.za'
            >
              inforeg@justice.gov.za
            </a>
          </p>

          <p className={`body-M ${styles.bm}`}>
            if you believe that your information is not being processed in
            accordance with applicable laws.
          </p>

          <p className={`body-M ${styles.bm}`}>
            Application of the above rights may vary depending on the type of
            data involved, and Lucky Beard’s particular basis for processing the
            personal data.
          </p>

          <h5 id='accessPersonalDataSA' className={`title-S ${styles.ts}`}>
            How Can I Access My Personal Data?
          </h5>

          <p className={`body-M ${styles.bm}`}>
            To make a request to exercise one of the above rights set out in (a)
            and (b) above, please contact
          </p>

          <p className={`body-M ${styles.bm}`}>
            <a
              className={`utility-link ${styles.link}`}
              href='mailto:privacy@luckybeard.com'
            >
              privacy@luckybeard.com
            </a>
          </p>

          <p className={`body-M ${styles.bm}`}>
            We will consider and act upon any requests in accordance with
            applicable data protection laws. Please note that we may request
            specific information from you to enable us to confirm your identity
            and right to access, as well as to search for and provide you with
            the personal data that we hold about you. We may, in limited
            circumstances, charge you a reasonable fee to access your personal
            data; however, we will advise you of any fee in advance.
          </p>

          <p className={`body-M ${styles.bm}`}>
            If we are relying on your consent to process your personal data, you
            have the right to withdraw your consent at any time, but note that
            we do not require your consent in order to process your information
            for our legitimate business interest. Please note however that this
            will not affect the lawfulness of the processing before its
            withdrawal.
          </p>
        </div>
      </div>

      <div className={styles.footerWrapper}>
        <Footer />
      </div>
    </ScrollablePage>
  );
};

export default PrivacyPolicy;
