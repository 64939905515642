import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import gsap, { Cubic } from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
gsap.registerPlugin(ScrollToPlugin);

const QuickFind = ({ scrollBoxRef }, ref) => {
  const [collapsed, setCollapsed] = useState(false);
  const [busy, setBusy] = useState(false);
  const breakpoints = useBreakpoint();
  const contentRef = useRef();

  useEffect(() => {
    if (breakpoints.md) {
      collapse();
    }
  }, [breakpoints]);

  const collapse = () => {
    setBusy(true);
    setCollapsed(true);
    gsap.to(contentRef.current, {
      duration: 0.25,
      ease: Cubic.easeOut,
      height: '0',
      onComplete: () => {
        setBusy(false);
      },
    });

    gsap.to(contentRef.current.children, {
      duration: 0.25,
      ease: Cubic.easeOut,
      x: -50,
      opacity: 0,
      stagger: 0.05,
    });
  };

  const expand = () => {
    setBusy(true);
    setCollapsed(false);
    gsap.to(contentRef.current, {
      duration: 0.25,
      ease: Cubic.easeOut,
      height: 'auto',
      onComplete: () => {
        setBusy(false);
      },
    });

    gsap.to(contentRef.current.children, {
      duration: 0.25,
      ease: Cubic.easeOut,
      x: 0,
      opacity: 1,
      stagger: 0.05,
    });
  };

  const scrollTo = (link) => {
    if (scrollBoxRef.current === null || scrollBoxRef.current === undefined) {
      gsap.to(window, {
        duration: 1,
        ease: Cubic.easeInOut,
        scrollTo: { y: link.id, offsetY: 35 },
      });
    } else {
      gsap.to(scrollBoxRef.current, {
        duration: 1,
        ease: Cubic.easeInOut,
        scrollTo: { y: link.id, offsetY: 35 },
      });
    }
  };

  const quickLinks = [
    {
      text: 'Who are we?',
      id: '#whoAreWe',
    },
    {
      text: 'What Does This Privacy Notice Cover?',
      id: '#whatDoesThisCover',
    },
    {
      text: 'What Personal Data Do We Collect?',
      id: '#whatPersonalDataDoWeCollect',
    },
    {
      text: 'Third party Cookies',
      id: '#thirdPartyCookies',
    },
    {
      text: 'What is our Legal Basis for Processing?',
      id: '#whatIsOurLegalBasis',
    },
    {
      text: 'Processing Personal Data for Marketing',
      id: '#processingPersonalData',
    },
    {
      text: 'Do We Share Your Personal Data?',
      id: '#doWeShare',
    },
    {
      text: 'International Transfers',
      id: '#internationalTransfers',
    },
    {
      text: ' How Long Will We Keep Your Personal Data?',
      id: '#dataKeeping',
    },
    {
      text: 'How Do We Keep Your Data Secure?',
      id: '#keepSecure',
    },
    {
      text: 'What are my Rights?',
      id: '#myRights',
    },
    {
      text: 'How Can I Access My Personal Data?',
      id: '#accessPersonalData',
    },
    {
      text: 'How Do I Contact You?',
      id: '#contactYou',
    },
    {
      text: 'Jurisdiction Specific Requirements South Africa',
      id: '#jurisdictionSA',
    },
    {
      text: 'International Transfers',
      id: '#internationalTransfersLocal',
    },
    {
      text: 'What Are My Rights?​​',
      id: '#myRightsSA',
    },
    {
      text: 'How Can I Access My Personal Data?',
      id: '#accessPersonalDataSA',
    },
  ];

  return (
    <div ref={ref} className={`${styles.quickFind}`}>
      <div
        onClick={() => {
          if (!busy) collapsed ? expand() : collapse();
        }}
        className={`${styles.accordionButton}`}
      >
        <span className={'title-S semi-bold'}>Quick find</span>
        <span
          className={`icon-chevron-down ${styles.accordionIcon} ${
            collapsed ? styles.closed : styles.open
          }`}
        ></span>
      </div>
      <div ref={contentRef} className={`${styles.content}`}>
        {quickLinks.map((link, i) => (
          <div
            onClick={() => {
              scrollTo(link);
            }}
            key={`link-${i}`}
            className={styles.link}
          >
            <span className={'body-S'}>{link.text}</span>
            <span className={'icon-arrow-right'}></span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default forwardRef(QuickFind);
