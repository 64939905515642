// extracted by mini-css-extract-plugin
export var privacyWrapper = "index-module--privacy-wrapper--c19y+";
export var header = "index-module--header--uTtym";
export var title = "index-module--title--anacR";
export var description = "index-module--description--sPtyD";
export var squigglyWrapper = "index-module--squiggly-wrapper--jhryn";
export var container = "index-module--container--r1ojw";
export var link = "index-module--link--qTA4h";
export var ts = "index-module--ts--Sw2bT";
export var bm = "index-module--bm--mYr0+";
export var table = "index-module--table--Il0VU";
export var row = "index-module--row--H+IjM";
export var dividerTop = "index-module--divider-top--jSU9O";
export var dividerBottom = "index-module--divider-bottom--xpcnv";
export var column = "index-module--column--x3tte";
export var flex = "index-module--flex--VjZ0+";
export var f1 = "index-module--f1--bJeim";
export var f2 = "index-module--f2--6326Y";
export var f3 = "index-module--f3--mq28O";
export var hDivider = "index-module--h-divider--FGkDE";
export var purpose = "index-module--purpose--E4T4A";
export var par = "index-module--par--4bXy5";
export var footerWrapper = "index-module--footer-wrapper--enOnX";