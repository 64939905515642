import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import TopNav from '../../components/topnav';
import Menu from '../../components/topnav/menu';
import TypeformChat from '../../components/typeform-chat';
import useChatBot from '../../hooks/useChatBot';
import { useSelector } from 'react-redux';
import Lightsaber from '../../components/lightsaber';
// import blur from '';
import { StaticImage } from 'gatsby-plugin-image';
import blur from '../../assets/images/gradients/blur.png';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Preloader from '../../components/preloader';
import ScrollDownIndicator from '../../components/scroll-down-indicator';
import gsap from 'gsap';
import { isMobile } from 'react-device-detect';
import useCombinedRefs from '../../hooks/useCombinedRefs';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
const Content = ({hideGradient, gradientRef, children}) => {
  return (
    <div ref={gradientRef} className={'scrollable-content-container'}>
      {!hideGradient && (
        <img
          alt="A gradient blur with a variety of colour"
          src={blur}
          className={`${styles.shape} ${styles.gradientBackground} blur`}
        />
      )}
      <div className={'content-wrapper'} style={{ zIndex: 2 }}>
        {children}
      </div>
    </div>
  );
};

const ScrollablePage = ({ children, simpleBarRef, hideGradient }, ref) => {
  const chatBotRef = useChatBot();
  const gradientRef = useRef();
  const menuOpen = useSelector((state) => state.ui.menuOpen || false);
  // const simpleBarRef = useRef();
  const breakpoints = useBreakpoint();
  const combinedRef = useCombinedRefs(ref);
  const showPreloader = useSelector(
    (state) => state.preloading.isLoading || false
  );

  useEffect(() => {
    if (!showPreloader) {
      // ScrollTrigger.refresh();
    }
  }, [showPreloader]);
  useEffect(() => {
    // start from warmer tones
    if (!gradientRef.current) return;
    gsap.set(gradientRef.current.querySelector('.blur'), {
      filter: 'hue-rotate(45deg)',
    });
    // animates hue rotation
    gsap.to(gradientRef.current.querySelector('.blur'), {
      duration: 10,
      filter: 'hue-rotate(-100deg)',
      repeat: -1,
      yoyo: true,
    });

    // as you scroll down the gradient position moves
    // gsap.to(gradientRef.current.querySelector('.blur'), {
    //   top: 100,
    //   right: 0,
    //   scrollTrigger: {
    //     trigger: '.content-wrapper',
    //     scrub: true,
    //     start: '5 top',
    //     end: 'bottom top',
    //     // markers: true,
    //     scroller: simpleBarRef.current,
    //   },
    // });
  }, []);

  useEffect(() => {
    if (!isMobile) return;
    // combinedRef.current.scrollTop = 0;
    gsap.to(window, {duration:0.01, scrollTo: 0});
  }, []);

  return (
    <div ref={combinedRef} className={`${styles.caseStudyWrapper} cs-wrapper`}>
      {/* <Lightsaber /> */}

      {!breakpoints.sm && <ScrollDownIndicator />}
      <TopNav hideLogo={false} />
      <Menu />
      <TypeformChat />
      {!breakpoints.sm ? (
        <SimpleBar
          onLoad={() => {
            ScrollTrigger.refresh();
          }}
          className={`${styles.caseStudySimplebar} scroll-container`}
          scrollableNodeProps={{ ref: simpleBarRef }}
          style={{ maxHeight: '100vh', height: menuOpen ? '100vh' : 'auto' }}
        >
          <Content children={children} gradientRef={gradientRef} hideGradient={hideGradient} />
        </SimpleBar>
      ) : (
        <Content children={children} gradientRef={gradientRef} hideGradient={hideGradient} />
      )}

      {showPreloader && <Preloader />}
    </div>
  );
};

export default forwardRef(ScrollablePage);
